export const review_credicapital = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit loan application",
    "es-MX": "Firma de solicitud de préstamo",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    {
      name: "review",
      title: {
        en: "Review your loan application",
        "es-MX": "Revisa tu solicitud de préstamo",
      },
      elements: [
        {
          type: "panel",
          title: {
            en: "The loan terms:",
            "es-MX": "Condiciones del préstamo:",
          },
          elements: [
            {
              type: "expression",
              title: {
                en: "BRANCH OFFICE",
                "es-MX": "SUCURSAL",
              },
              expression: "{loan.loan_officer.branch.name}",
            },
            {
              type: "expression",
              name: "today_date",
              expression: "daysFromNow(0, 0, 'es-MX')",
              title: { en: "Date", "es-MX": "Fecha" },
            },
            {
              type: "expression",
              title: {
                en: "CUSTOMER NAME",
                "es-MX": "NOMBRE DEL CLIENTE",
              },
              expression: `getFullName({first_name},{middle_name},{surname},{second_surname})`,
            },
            {
              type: "expression",
              expression: "age({date_of_birth})",
              title: { en: "AGE", "es-MX": "EDAD" },
            },
            {
              type: "expression",
              expression: "{home_street}",
              title: {
                en: "ADDRESS",
                "es-MX": "DOMICILIO",
              },
            },
            {
              type: "expression",
              expression: "{custom_text_59}",
              title: {
                en: "Business Type",
                "es-MX": "TIPO DE NEGOCIO",
              },
            },
            {
              type: "expression",
              expression: "{phone_number}",
              title: {
                en: "TELEPHONE",
                "es-MX": "TELÉFONO",
              },
            },
            {
              type: "expression",
              expression: "capitalize('first', {custom_text_29})",
              title: {
                en: "MARITAL STATUS",
                "es-MX": "ESTADO CIVIL",
              },
            },
            {
              type: "expression",
              expression: "CrediProductivo",
              title: {
                en: "PRODUCT NAME",
                "es-MX": "NOMBRE DEL PRODUCTO",
              },
            },
            {
              type: "expression",
              expression:
              //temporary solution
              "templateConditional(templateConditional(sum(multiply({loan.amount},1.1),{charge_ref_dict.insurance.total}),multiply({loan.amount},1.1),{custom_text_22},'si'),templateConditional(sum({loan.amount},{charge_ref_dict.insurance.total}),{loan.amount},{custom_text_22},'si'),{custom_text_141},'financiada')",
              //"templateConditional(sum({amount}, {charge_ref_dict.insurance.total}), {amount},{custom_text_22},'si')",
              title: {
                en: "AMOUNT REQUESTED",
                "es-MX": "MONTO SOLICITADO",
              },
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "{loan_term}",
              title: {
                en: "CREDIT TERM",
                "es-MX": "PLAZO DE CRÉDITO",
              },
            },
            {
              type: "expression",
              expression: "capitalize('first', {custom_text_28})",
              title: {
                en: "PAYMENT FREQUENCY",
                "es-MX": "FRECUENCIA DE PAGO",
              },
            },
            {
              type: "expression",
              expression: "{custom_float_49}", // TODO this used to be total_interest and aparently its supposed to be same as Tasa ordinaria
              title: {
                en: "INTEREST RATE",
                "es-MX": "TASA DE INTERÉS",
              },
            },
            {
              type: "expression",
              expression: "NUEVO",
              title: {
                en: "CUSTOMER TYPE",
                "es-MX": "TIPO DE CLIENTE",
              },
            },
            {
              type: "expression",
              title: {
                en: "GUARANTEE OR COMMISSION",
                "es-MX": "GARANTÍA O COMISIÓN",
              },
              expression:
                "templateConditional('GARANTÍA','COMISIÓN',{custom_text_24},'garantia')",
            },
            {
              type: "expression",
              expression: "{charge_ref_dict.guarantee.total}",
              title: {
                en: "AMOUNT",
                "es-MX": "MONTO",
              },
              visibleIf: "{custom_text_24} == 'garantia'",
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression:
                "fixed({charge_ref_dict.commission.total}-{charge_ref_dict.vat_on_commission.total})",
              title: {
                en: "AMOUNT",
                "es-MX": "MONTO CREDICAPITAL",
              },
              visibleIf: "{custom_text_24} == 'comision'",
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "fixed({charge_ref_dict.vat_on_commission.total})",
              title: {
                en: "AMOUNT",
                "es-MX": "IVA 16%",
              },
              visibleIf: "{custom_text_24} == 'comision'",
            },
            {
              type: "expression",
              expression:
                "fixed(templateConditional(sum({charge_ref_dict.commission.total}, multiply({charge_ref_dict.insurance.total}, 0.04)), {charge_ref_dict.commission.total}, {custom_text_22}, 'si'))",
              title: {
                en: "AMOUNT",
                "es-MX": "TOTAL COMISIÓN",
              },
              visibleIf: "{custom_text_24} == 'comision'",
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "{charge_ref_dict.insurance.total}",
              title: {
                en: "LIFE INSURANCE",
                "es-MX": "SEGURO DE VIDA",
              },
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "expression",
              expression: "displayColumnsFromJsonfield({custom_jsonfield_13}, 'descripcion_de_la_garantia')",
              title: {
                en: "WARRANTY",
                "es-MX": "GARANTÍA",
              },
              visibleIf: "{custom_text_130} == 'si'",
            },
            {
              type: "html",
              html: {
                en: "THE FIELDS OF THE CREDIT CHARACTERISTICS MAY BE EDITABLE IN CASE OF MODIFYING THE CREDIT CONDITIONS",
                "es-MX":
                  "LOS CAMPOS DE LAS CARACTERÍSTICAS DEL CRÉDITO PODRÁN SER EDITABLES EN CASO DE MODIFICAR LAS CONDICIONES DEL CRÉDITO",
              }
            },
          ],
        },
      ],
    },
  ],
};

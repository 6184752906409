import env from "../../env";
import {expressions, regexValidators} from "../../survey/validators";

const required = env.ENDPOINT !== "local";

const applicant_information = {
  title: {
    en: "Applicant Information",
    rw: "Amakuru yusaba",
  },
  name: "applicant",
  elements: [
    {
      title: { en: "Applicant first Name", rw: "Izina ry’idini" },
      name: "first_name",
      type: "text",
      description: {
        en: "If not existent, leave empty",
        rw: "Niba nta rindi zina ufite, hasige ubusa",
      },
    },
    {
      title: { en: "Applicant last name *", rw: "Izina ry’umuryango*" },
      name: "last_name",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Can you share a picture for yourself? *",
        rw: "Waduha ifoto yawe? *",
      },
      name: "profile_avatar",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Date of Birth *",
        rw: "Itariki yamavuko *",
      },
      name: "date_of_birth",
      type: "text",
      inputType: "date",
      validators: [
        {
          type: "expression",
          text: {
            en: "You must be older than 18 and younger than 70 to apply",
            rw: "Ugomba nibura kuba ufite imyaka 18 kandi utarengeje imyaka 70 kugirango usabe inguzanyo",    
          },
          expression:
            "(age({date_of_birth}) >= 18) && (age({date_of_birth}) < 70)",
        },
      ],
      isRequired: required,
    },
    {
      title: {
        en: "What is your gender? *",
        rw: "Igitsina ni ikihe? *",
      },
      name: "gender",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "male", text: { en: "Male", rw: "Gabo" } },
        { value: "female", text: { en: "Female", rw: "Gore" } },
      ],
    },
  ]
}

const identification_information = {
  name: "identification",
  title: {
    en: "Identification Information",
    rw: "Amakuru ajyanye n'umwirondoro",
  },
  elements: [
    {
      name: "nationality",
      type: "dropdown",
      isRequired: required,
      storeOthersAsComment: false,
      hasOther: true,
      title: {
        en: "Nationality *",
        rw: "Ubwenegihugu *",
      },
      choices: [
        {
          value: "rwandan",
          text: {
            en: "Rwandan",
            rw: "Umunyarwanda",
          },
        },
      ],
    },
    {
      name: "id_number",
      type: "text",
      isRequired: required,
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 16 digits length",
            rw: "Imibare igomba kuba ari 16",
          },
          regex: "^[0-9]{16}$",
        },
      ],
      title: {
        en: "ID No. *",
        rw: "Nimero y'indangamuntu  *",
      },
    },
    {
      title: {
        en: "Front Image (Attachment) *",
        rw: "Ifoto y'imbere y'indangamuntu *",
      },
      description: {
        en: "If you don't have an ID card upload the replacement document.",
        rw: "Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura",
      },
      name: "id_file",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      name: "id_avatar",
      type: "panel",
      innerIndent: 1,
      title: {
        en: "Please verify that this card belongs to you",
        rw: "Genzura niba ikarita ari iyawe",
      },
      elements: [
        {
          name: "id_avatar_desc",
          type: "html",
          description: {
            en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
            rw: "Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe",
          },
          html: {
            en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
            rw: "Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe",
          },
        },
        {
          name: "id_avatar_desc_picture",
          type: "html",
          html: `<img alt="example" src='${require("../../assets/images/example_card.png")}' width='200'/>`,
        },
        {
          name: "id_file_with_avatar",
          type: "file_uploader",
          accept: "image/*,application/pdf",
          title: {
            en: "Identification Picture",
            rw: "Ibiranga ifoto",
          },
        },
      ],
    },
    {
      title: {
        en: "What is your marital status? *",
        rw: "Irangamimerere ni irihe? *",
      },
      name: "marital",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "married", text: { en: "Married", rw: "Ndubatse" } },
        { value: "single", text: { en: "Single", rw: "Ingaragu" } },
        { value: "divorced", text: { en: "Divorced", rw: "Yaratandukanye" } },
        { value: "widowed", text: { en: "Widowed", rw: "Umupfakare" },  visibleIf: "{gender} == 'male'" },
        { value: "widowed", text: { en: "Widowed", rw: "Umupfakazi" },  visibleIf: "{gender} == 'female'" },
      ],
    },
    {
      title: { en: "Full name of partner", rw: "Amazina y'uwo mwashakanye" },
      name: "custom_text_2",
      type: "text",
      visibleIf: "{marital} == 'married'",
      isRequired: required,
    },
    {
      name: "custom_text_3",
      type: "phone",
      isRequired: required,
      visibleIf: "{marital} == 'married'",
      validators: [
        expressions.phone_en("custom_text_3"),
        expressions.phone_length("custom_text_3"),
      ],
      title: {
        en: "Telephone number of your partner",
        rw: "Numero ya telephone y'uwo mwashakanye",
      },
    },
    {
      name: "custom_text_4",
      type: "text",
      isRequired: required,
      visibleIf: "{marital} == 'married'",
      validators: [
        {
          type: "regexvalidator",
          text: {
            en: "Number should be from 16 digits length",
            rw: "Imibare igomba kuba ari 16",
          },
          regex: "^[0-9]{16}$",
        },
      ],
      title: {
        en: "ID number of your partners",
        rw: "Numero y'indangamuntu",
      },
    },
    {
      title: {
        en: "Upload the ID of your partner",
        rw: "Shyiraho indangamuntu y'uwo mwashakanye",
      },
      description: {
        en: "If you don't have an ID card upload the replacement document.",
        rw: "Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura",
      },
      name: "file_3",
      visibleIf: "{marital} == 'married'",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Have you applied before for a loan with MoneyPhone?",
        rw: "Wigeze usaba inguzanyo ukoresheje MoneyPhone ?",
      },
      name: "custom_text_1",
      type: "dropdown",
      choices: [
        {
          value: "yes",
          text: { en: "Yes", rw: "Yego" },
        },
        {
          value: "no",
          text: { en: "No", rw: "Oya" },
        },
      ],
    },
  ],
}

const residence_and_home_information = (clientUrlForCatalog) => [
  {
    title: {
      en: "Residence & Home Information",
      rw: "Amakuru yaho utuye",
    },
    name: "home",
    elements: [
      {
        title: {
          en: "Where do you live?",
          rw: "Utuye he?",
        },
        name: "panel_home",
        type: "panel",
        innerIndent: 1,
        elements: [
          {
            name: "province",
            type: "dropdown",
            isRequired: required,
            title: {
              en: "Province *",
              rw: "Intara *",
            },
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/provinces/`,
              path: "results",
              titleName: "value",
              valueName: "value",
              attachOriginalItems: true,
            },
          },
          { type: "text", name: "Idprovince", visible: false },
          {
            name: "district",
            type: "dropdown",
            isRequired: required,
            title: {
              en: "District *",
              rw: "Akarere *",
            },
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/districts/?province_id={Idprovince}`,
              path: "results",
              titleName: "value",
              valueName: "value",
              attachOriginalItems: true,
            },
          },
          { type: "text", name: "Iddistrict", visible: false },
          {
            name: "home_country", // Mapped to home country for now. Field on the backend will be renamed later.
            type: "dropdown",
            isRequired: required,
            title: {
              en: "Sector *",
              rw: "Umurenge *",
            },
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/sectors/?district_id={Iddistrict}`,
              path: "results",
              titleName: "value",
              valueName: "value",
              attachOriginalItems: true,
            },
          },
          { type: "text", name: "Idsector", visible: false },
          {
            name: "cell",
            type: "dropdown",
            isRequired: required,
            title: {
              en: "Cell *",
              rw: "Akagali *",
            },
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/cells/?sector_id={Idsector}`,
              path: "results",
              titleName: "value",
              valueName: "value",
              attachOriginalItems: true,
            },
          },
          { type: "text", name: "Idcell", visible: false },
          {
            name: "village",
            type: "dropdown",
            isRequired: required,
            title: {
              en: "Village *",
              rw: "Umudugudu *",
            },
            renderAs: "select2",
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/tenant-data/villages/?cell_id={Idcell}`,
              path: "results",
              titleName: "value",
              valueName: "value",
              attachOriginalItems: true,
            },
          },
        ],
      },
      {
        name: "phone_number",
        type: "phone",
        isRequired: required,
        validators: [expressions.phone_en("phone_number"), expressions.phone_length("phone_number")],
        title: {
          en: "Mobile No. 1 *",
          rw: "Numero ya telefone *",
        },
      },
    ],
  },
];

const production_information_default = {
  name: "production",
  title: {
    en: "Production Information",
    rw: "Amakuru yumusaruro",
  },
  description: {
    en: "The production information provided will be verified and confirmed by the cooperative.",
    rw: "Amakuru y'umusaruro azasuzumwa kandi yemezwe na koperative",
  },
  elements: [
    {
      title: {
        en: "Provide us with production information of 2 previous agriculture seasons",
        rw: "Duhe amakuru y'umusaruro ku bihembwe 2 bishize",
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Season 1",
            rw: "Igihembwe cya 1",
          },
          name: "agricultural_season_1",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_1",
              type: "text",
              inputType: "number",
              title: {
                en: "Cultivated Land of the selected crop (hectare)",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_1",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Production of the selected crop in KGs",
                rw: "Ingano y'umusaruro kugihigwa wahisemo  muri kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_1",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_1",
              isRequired: required,
              type: "text",
              inputType: "number",
              title: {
                en: "Price of the selected crop/kg in RWF",
                rw: "Igiciro ku kilo ku gihingwa wahisemo(RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_1",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Sales of selected crop in RWF",
                rw: "Amafaranga winjije yose ku gihingwa wahisemo (RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
          ],
        },
        {
          title: {
            en: "Season 2",
            rw: "Igihembwe cya 2",
          },
          name: "agricultural_season_2",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Production of the selected crop in KG",
                rw: "Ingano y'umusaruro kugihigwa wahisemo  muri kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_2",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_2",
              type: "text",
              isRequired: required,
              title: {
                en: "Price of the selected crop/kg in RWF",
                rw: "Igiciro ku kilo ku gihingwa wahisemo(RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_2",
              type: "text",
              isRequired: required,
              title: {
                en: "Sales of the selected crop in Rwf",
                rw: "Amafaranga winjije yose ku gihingwa wahisemo (RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
          ],
        },
        {
          title: {
            en: "Information on current season of the selected crop",
            rw: "Amakuru ku gihembwe ugiye guhinga",
          },
          name: "current_agricultural_season",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso uteganya guhinga muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected production of selected crop in KG",
                rw: "Ingano y'umusaruro uteganyijwe  muri kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_current_season",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko uteganya guhinga",
              },
            },
            {
              name: "price_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected price of selected crop/kg in RWF",
                rw: "Igiciro uteganya kugurishirizaho (RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected sales of selected crop in Rwf",
                rw: "Amafaranga yose uteganya kwinjiza (RWF)",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "plot_ownership_season_1",
              type: "radiogroup",
              isRequired: required,
              title: {
                en: "Do you own a land",
                rw: "Ufite ubutaka uhingaho ?",
              },
              choices: [
                {
                  value: true,
                  text: {
                    en: "Yes",
                    rw: "Yego",
                  },
                },
                {
                  value: false,
                  text: {
                    en: "No",
                    rw: "Oya",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

const production_information_chili = {
  name: "production",
  title: {
    en: "Production Information",
    rw: "Amakuru yumusaruro",
  },
  description: {
    en: "The production information provided will be verified and confirmed by the cooperative.",
    rw: "Amakuru y'umusaruro azasuzumwa kandi yemezwe na koperative",
  },
  elements: [
    {
      title: {
        en: "Provide us with production information of 2 previous agriculture seasons",
        rw: "Duhe amakuru y'umusaruro ku bihembwe 2 bishize",
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Season 1",
            rw: "Igihembwe cya 1",
          },
          name: "agricultural_season_1",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_1",
              type: "text",
              inputType: "number",
              title: {
                en: "Cultivated Land of the selected crop (hectare)",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_1",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuye kuri rwiyemezamirimo muri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_1",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_1",
              isRequired: required,
              type: "text",
              inputType: "number",
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe na rwiyemezamirimo kuri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_1",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe na Rwiyemezamirimo",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
          ],
        },
        {
          title: {
            en: "Season 2",
            rw: "Igihembwe cya 2",
          },
          name: "agricultural_season_2",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuye kuri rwiyemezamirimo muri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_2",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_2",
              type: "text",
              isRequired: required,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe na rwiyemezamirimo kuri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_2",
              type: "text",
              isRequired: required,
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe na Rwiyemezamirimo",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
          ],
        },
        {
          title: {
            en: "Information on current season of the selected crop",
            rw: "Amakuru ku gihembwe ugiye guhinga",
          },
          name: "current_agricultural_season",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso uteganya guhinga muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro uteganya kuzagemura kuri rwiyemezamirimo",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_current_season",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko uteganya guhinga",
              },
            },
            {
              name: "price_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected price paid by the exporter per 1 Kg",
                rw: "Igiciro uteganya kuzishyurwa na rwiyemezamirimo kuri kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_current_season",
              type: "text",
              isRequired: required,
              title: {
                en: "Expected total amount received form exporter",
                rw: "Amafaranga yose uteganya kuzishyurwa na rwiyemezamirimo",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "plot_ownership_season_1",
              type: "radiogroup",
              isRequired: required,
              title: {
                en: "Do you own a land",
                rw: "Ufite ubutaka uhingaho ?",
              },
              choices: [
                {
                  value: true,
                  text: {
                    en: "Yes",
                    rw: "Yego",
                  },
                },
                {
                  value: false,
                  text: {
                    en: "No",
                    rw: "Oya",
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

const production_information_tea = {
  name: "production",
  title: {
    en: "Production Information",
    rw: "Amakuru yumusaruro",
  },
  description: {
    en: "The production information provided will be verified and confirmed by the cooperative.",
    rw: "Amakuru y'umusaruro azasuzumwa kandi yemezwe na koperative",
  },
  elements: [
    {
      title: {
        en: "Provide with us production information of 3 previous months",
        rw: "Duhe amakuru y'umusaruro y'amezi atatu ashize",
      },
      name: "panel_home",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "Month 1",
            rw: "Ukwezi kwa 1",
          },
          name: "agricultural_season_1",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_1",
              type: "text",
              inputType: "number",
              title: {
                en: "Cultivated Land of the selected crop (hectare)",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_1",
              type: "text",
              inputType: "number",
              isRequired: required,
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_1",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_1",
              isRequired: required,
              type: "text",
              inputType: "number",
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_1",
              type: "expression",
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n’uruganda",
              },
              expression: "{supply_volume_kg_season_1} * {price_season_1}"
            },
          ],
        },
        {
          title: {
            en: "Month 2",
            rw: "Ukwezi kwa 2",
          },
          name: "agricultural_season_2",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_2",
              isRequired: required,
              type: "text",
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_2",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_2",
              type: "text",
              isRequired: required,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_2",
              type: "expression",
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n’uruganda",
              },
              expression: "{supply_volume_kg_season_2} * {price_season_2}"
            },
          ],
        },
        {
          title: {
            en: "Month 3",
            rw: "Ukwezi kwa 3",
          },
          name: "agricultural_season_3",
          type: "panel",
          innerIndent: 1,
          elements: [
            {
              name: "land_size_season_3",
              isRequired: required,
              type: "text",
              title: {
                en: "Cultivated land of the selected crop in hectare",
                rw: "Ingano y'ubuso wahinzeho igihingwa wahisemo muri hegitare",
              },
              validators: [regexValidators.max_three_decimal_places()],
            },
            {
              name: "supply_volume_kg_season_3",
              isRequired: required,
              type: "text",
              title: {
                en: "Production supplied to the exporter in Kgs",
                rw: "Ingano yumusaruro wagemuwe kuruganda muri Kilogarama",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "variety_season_3",
              type: "text",
              title: {
                en: "Variety",
                rw: "Ubwoko bw'igihingwa wahisemo",
              },
            },
            {
              name: "price_season_3",
              type: "text",
              isRequired: required,
              title: {
                en: "Price paid by the exporter per 1 Kg",
                rw: "Igiciro wishyuwe nuruganda ku kilo kimwe",
              },
              validators: [regexValidators.max_two_decimal_places()],
            },
            {
              name: "sales_season_3",
              type: "expression",
              title: {
                en: "Total amount received form exporter",
                rw: "Amafaranga yose wishyuwe n’uruganda",
              },
              expression: "{supply_volume_kg_season_3} * {price_season_3}"
            },
          ],
        },
      ],
    },
    {
      name: "plot_ownership_season_1",
      type: "radiogroup",
      isRequired: required,
      title: {
        en: "Do you own a land",
        rw: "Ufite ubutaka uhingaho ?",
      },
      choices: [
        {
          value: true,
          text: {
            en: "Yes",
            rw: "Yego",
          },
        },
        {
          value: false,
          text: {
            en: "No",
            rw: "Oya",
          },
        },
      ],
    },
  ],
}

const extra_questions = {
  name: "practice",
  title: {
    en: "Farming Practice Information",
    rw: "Amakuru ajyanye n'imihingire",
  },
  elements: [
    {
      title: {
        en: "On what are you going to spend the loan",
        rw: "Inguzanyo uzayikoresha iki ?",
      },
      name: "custom_jsonfield_1",
      type: "checkbox",
      isRequired: required,
      hasOther: true,
      otherText: { en: "Other", rw: "Ibindi" },
      storeOthersAsComment: false,
      choices: [
        {
          value: "Equipment",
          text: {
            en: "Equipment",
            rw: "Ibikoresho",
          },
        },
        {
          value: "Fertilizer",
          text: {
            en: "Fertilizer",
            rw: "Ifumbire",
          },
        },
        {
          value: "Labor",
          text: {
            en: "Labor",
            rw: "Abakozi",
          },
        },
        {
          value: "Land lending",
          text: {
            en: "Land lending",
            rw: "Gukodesha ubutaka",
          },
        },
        {
          value: "Seeds",
          text: {
            en: "Seeds",
            rw: "Kugura Imbuto",
          },
        },
        {
          value: "Transport",
          text: {
            en: "Transport",
            rw: "Ubwikorezi",
          },
        },
      ],
    },
    {
      title: {
        en: "For what will you use the profit you are going to make?",
        rw: "Uzaresha iki inyungu uzabona?",
      },
      name: "custom_jsonfield_2",
      type: "checkbox",
      hasOther: true,
      otherText: { en: "Other", rw: "Ibindi" },
      storeOthersAsComment: false,
      isRequired: required,
      choices: [
        {
          value: "Agriculture investments",
          text: {
            en: "Agriculture investments",
            rw: "Nzongera igishoro mubuhinzi",
          },
        },
        {
          value: "Costs of living",
          text: {
            en: "Costs of living",
            rw: "Kuvugurura inzu",
          },
        },
        {
          value: "Education",
          text: {
            en: "Education",
            rw: "Uburezi",
          },
        },
        {
          value: "Health care",
          text: {
            en: "Health care",
            rw: "Ubwishingizi mu kwivuza",
          },
        },
      ],
    },
    {
      title: {
        en: "What other crops are you cultivating?",
        rw: "Nibihe  bindi bihingwa uhinga ?",
      },
      name: "custom_jsonfield_3",
      type: "checkbox",
      hasOther: true,
      otherText: { en: "Other", rw: "Ibindi" },
      hasNone: true,
      noneText: { en: "None", rw: "Ntanakimwe" },
      storeOthersAsComment: false,
      isRequired: required,
      choices: [
        {
          value: "Beans",
          text: {
            en: "Beans",
            rw: "Ibishyimbo",
          },
        },
        {
          value: "Horticulture",
          text: {
            en: "Horticulture",
            rw: "Imboga n'imbuto",
          },
        },
        {
          value: "Maize",
          text: {
            en: "Maize",
            rw: "Ibigori",
          },
        },
        {
          value: "Potato",
          text: {
            en: "Potato",
            rw: "Ibirayi",
          },
        },
        {
          value: "Pyrethrum",
          text: {
            en: "Pyrethrum",
            rw: "Ibireti",
          },
        },
        {
          value: "Chili",
          text: {
            en: "Chili",
            rw: "Urusenda",
          },
        },
        {
          value: "Tea",
          text: {
            en: "Tea",
            rw: "Icyayi",
          },
        },
      ],
    },
    {
      title: {
        en: "Do you have access to equipment or tools?",
        rw: "Ufite ibihe bikoresho muri ibi bikurikira ?",
      },
      name: "custom_jsonfield_4",
      type: "checkbox",
      hasOther: true,
      otherText: { en: "Other", rw: "Ibindi" },
      hasNone: true,
      noneText: { en: "None", rw: "Ntanakimwe" },
      storeOthersAsComment: false,
      isRequired: required,
      choices: [
        {
          value: "Bike",
          text: {
            en: "Bike",
            rw: "Igare",
          },
        },
        {
          value: "Car",
          text: {
            en: "Car",
            rw: "Imodoka",
          },
        },
        {
          value: "Chemical spray",
          text: {
            en: "Chemical spray",
            rw: "Imashini itera umuti",
          },
        },
        {
          value: "Irrigation system",
          text: {
            en: "Irrigation system",
            rw: "Imashini yuhira",
          },
        },
      ],
    },
    {
      title: {
        en: "Do you have access to sustainable technology?",
        rw: "Ufite ikoranabuhanga rirambye ?",
      },
      name: "custom_jsonfield_5",
      type: "checkbox",
      hasOther: true,
      otherText: { en: "Other", rw: "Ibindi" },
      hasNone: true,
      noneText: { en: "None", rw: "Ntanakimwe" },
      storeOthersAsComment: false,
      isRequired: required,
      choices: [
        {
          value: "Clean cooking",
          text: {
            en: "Clean cooking",
            rw: "Ibicanwa bitangiza ikirere",
          },
        },
        {
          value: "Solar energy",
          text: {
            en: "Solar energy",
            rw: "Imirasire y'izuba",
          },
        },
      ],
    },
    {
      title: {
        en: "How organic do you practice farming?",
        rw: "Nigute ukora ubuhinzi bw'umwimerere?",
      },
      name: "custom_text_30",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "Completely",
          text: {
            en: "Completely",
            rw: "Muburyo bwuzuye (100%)",
          },
        },
        {
          value: "Over 75%",
          text: {
            en: "Over 75%",
            rw: "Hejuru ya 75%",
          },
        },
        {
          value: "Between 50 - 75%",
          text: {
            en: "Between 50 - 75%",
            rw: "Hagati ya 50-75%",
          },
        },
        {
          value: "Between 25 - 50%",
          text: {
            en: "Between 25 - 50%",
            rw: "Hagati ya 25%-50%",
          },
        },
        {
          value: "Less than 25% ",
          text: {
            en: "Less than 25% ",
            rw: "Hasi ya 25%",
          },
        },
        {
          value: "Not at all",
          text: {
            en: "Not at all",
            rw: "Nago mbukoresha",
          },
        },
      ],
    },
  ],
}

const financial_information = (clientUrlForCatalog) => [{
  name: "financial",
  title: {
    en: "Financial Information",
    rw: "Amakuru ku nguzanyo",
  },
  elements: [
    {
      title: {
        en: "How much do you want to borrow?",
        rw: "Urashaka kuguza angahe ?",
      },
      name: "amount",
      type: "nouislider",
      step: 1000,
      rangeMin: 100000,
      rangeMax: 1000000,
      isRequired: required,
      validators: [
        expressions.amount_in_range(100000, 1000000),
      ],
    },
    {
      type: "html",
      name: "amount_note",
      html: {
        en: "We inform you that a repayment period of {repayment_period} months has been approved for the requested crop loan.",
        rw: "Turakumenyesha igihe cyo kwishuyura cy'amazi {repayment_period} cyemejwe ku nguzanyo y'igihingwa",
      },
    },
    {
      title: {
        en: "Do you have a mobile wallet?",
        rw: "Ufite konti y'amafaranga kuri telephone?",
      },
      name: "custom_text_5",
      type: "dropdown",
      isRequired: required,
      choices: [
        {
          value: "yes",
          text: { en: "Yes", rw: "Yego" },
        },
        {
          value: "no",
          text: { en: "No", rw: "Oya" },
        },
      ],
    },
    {
      title: {
        en: "Select your mobile wallet",
        rw: "Hitamo konti y'amafaranga ya telephone ukoresha",
      },
      name: 'custom_text_6',
      type: 'dropdown',
      visibleIf: "{custom_text_5} == 'yes'",
      choices: [
        { value: "MTN", text: { en: "MTN", "rw": "MTN" } },
        { value: "Airtel", text: { en: "Airtel", "rw": "Airtel" } },
      ],
    },
    {
      title: {
        en: "Do you have a bank account?",
        rw: "Ufite konti muri Banki",
      },
      name: "custom_text_7",
      type: "dropdown",
      choices: [
        {
          value: "yes",
          text: { en: "Yes", rw: "Yego" },
        },
        {
          value: "no",
          text: { en: "No", rw: "Oya" },
        },
      ],
    },
    {
      type: "panel",
      visibleIf: '{custom_text_7} == "yes"',
      innerIndent: 1,
      name: "bank_panel",
      elements: [
        {
          title: {
            en: "Name of the Bank",
            rw: "Izina rya konti ya Banki",
          },
          name: "custom_text_8",
          type: "dropdown",
          isRequired: required,
          hasOther: true,
          otherText: { en: "Other", rw: "Ibindi" },
          storeOthersAsComment: false,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/tenant-data/bank_names/`,
            path: "results",
            titleName: "value",
            valueName: "value",
            attachOriginalItems: true,
          }
        },
        {
          title: {
            en: "Bank account number",
            rw: "Nimero ya konti ya Banki",
          },
          name: "custom_text_9",
          type: "text",
          isRequired: required,
        },
      ],
    },
    {
      title: {
        en: 'Where do you prefer to receive the money?',
        rw: 'Urashaka ko amafaranga yawe azanyuzwa hehe?',
      },
      name: 'preferred_payment_method',
      type: 'dropdown',
      isRequired: required,
      choices: [
        {
          value: 'mobile_wallet',
          text: { en: 'Mobile wallet', rw: 'Kuri konti ya telephone' },
        },
        {
          value: 'bank_account',
          text: { en: 'Bank account', rw: 'Konti ya Banki' },
        },
      ],
      validators: [
        {
          type: "expression",
          expression: "templateConditional({custom_text_6} notempty, {custom_text_9} notempty, {preferred_payment_method}, 'mobile_wallet')", 
          text: {
            en: "Please confirm the details for selection",
            rw: "Emeza amahitamo yawe",
          }
        },
      ],
    },
    {
      html: {
        en: "We will transfer the money to the wallet connected to the phone number used in this application",
        "rw": "Tuzohereza amafaranga kuri nimero watanze usaba iyi inguzanyo ndetse ihuye numwirondoro wawe",
      },
      visibleIf: "{preferred_payment_method} == 'mobile_wallet'",
      type: "html",
    },
    {
      html: {
        en: "We will transfer the money to the bank account you mentioned above connected to the identification used during the application",
        "rw": "Tuzohereza amafaranga kuri konti watanze haruguru kandi ihuye numwirondoro wawe bwite",
      },
      visibleIf: "{preferred_payment_method} == 'bank_account'",
      type: "html",
    },
    {
      name: "file_2",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      title: {
        en: "Upload your Bank Statement or Mobile Money statement",
        rw: "Shyiraho urupapuro rwemeraka uko ubitsa cg ubikuza kuri Banki cg Telephone",
      },
    },
    {
      title: {
        en: "Do you own a smartphone?",
        rw: "Ufite telefoni igendanwa ya smartphone?",
      },
      name: "custom_bool_2",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: true,
          text: { en: "Yes", rw: "Yego" },
        },
        {
          value: false,
          text: { en: "No", rw: "Oya" },
        },
      ]
    },
  ],
}]

const guarantor_information = (clientUrlForCatalog) => [{
  title: {
    en: "Information related to your Guarantor",
    rw: "Amakuru ajyanye n'umwishingizi wawe",
  },
  name: "home",
  elements: [
    // Cooperatives
    {
      name: "cooperative_name",
      type: "dropdown",
      isRequired: required,
      title: {
        en: "Name of the Cooperative *",
        rw: "Izina rya Cooperative *",
      },
      choicesByUrl: {
        url: `${clientUrlForCatalog}/api/cooperatives/?product={loan_purpose}`,
        path: "results",
        titleName: "name",
        valueName: "name",
        attachOriginalItems: true,
      },
    },
  ],
}]

export const intake_rwanda_direct_model_pages_default = (clientUrlForCatalog) => [
  // Page 1: Applicant Information
  applicant_information,
  // Page 2: Identification Information
  identification_information,
  // Page 3: Residence & Home Information
  ...residence_and_home_information(clientUrlForCatalog),
  // Page 4: Production Information
  production_information_default,
  // page 5 Extra questions
  extra_questions,
  // Page 6: Financial Information
  ...financial_information(clientUrlForCatalog),
  // Page 7: Information related to your Guarantor
  ...guarantor_information(clientUrlForCatalog),
];

export const intake_rwanda_direct_model_pages_chili = (clientUrlForCatalog) => [
  // Page 1: Applicant Information
  applicant_information,
  // Page 2: Identification Information
  identification_information,
  // Page 3: Residence & Home Information
  ...residence_and_home_information(clientUrlForCatalog),
  // Page 4: Production Information
  production_information_chili,
  // page 5 Extra questions
  extra_questions,
  // Page 6: Financial Information
  ...financial_information(clientUrlForCatalog),
  // Page 7: Information related to your Guarantor
  ...guarantor_information(clientUrlForCatalog),
];

export const intake_rwanda_direct_model_pages_tea = (clientUrlForCatalog) => [
  // Page 1: Applicant Information
  applicant_information,
  // Page 2: Identification Information
  identification_information,
  // Page 3: Residence & Home Information
  ...residence_and_home_information(clientUrlForCatalog),
  // Page 4: Production Information
  production_information_tea,
  // page 5 Extra questions
  extra_questions,
  // Page 6: Financial Information
  ...financial_information(clientUrlForCatalog),
  // Page 7: Information related to your Guarantor
  ...guarantor_information(clientUrlForCatalog),
];
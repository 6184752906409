import env from "../../env";
import { expressions } from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const guarantor_credicapital_new_client = (clientUrlForCatalog) => ({
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Page 1
    {
      title: { en: "Guarantor acceptance", "es-MX": "Aceptación de aval" },
      name: "aceptacion_de_aval",
      elements: [
        {
          title: {
            en: "Welcome {guarantor_first_name}, you are invited to be the guarantor/cooperative of {user_first_name} for a loan amount of {amount} RWF. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            "es-MX":
              "Bienvenido {guarantor_first_name}, estás propuesto para ser el aval de {user_first_name} por un monto de préstamo de $ {amount}. Para cumplir con este requerimiento, se te pedirá información sobre ti. Selecciona aceptas o no. Si tiene alguna pregunta, por favor contáctenos.",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: "I accept",
                "es-MX": "Acepto",
              },
            },
            {
              value: true,
              text: {
                en: "I don't accept",
                "es-MX": "No acepto",
              },
            },
          ],
        },
      ],
    },
    // Page 2
    {
      title: {
        en: "CURP Authorization",
        "es-MX": "Autorización de CURP",
      },
      name: "curp_authorization",
      elements: [
        {
          title: {
            en: "curp",
            "es-MX": "CURP*",
          },
          name: "custom_text_108",
          type: "text",
          inputType: "text",
          isRequired: required,
          validators: [expressions.curp()],
          placeHolder: "HEGG560427MVZRRL04",
        },
        {
          type: "html",
          name: "Consultar CURP",
          html: "<button hidden class='curp-button'>Consultar CURP</button>",
        },
        {
          type: "html",
          name: "Error consulta CURP",
          html: "<p hidden class='sv_q_erbox' id='curp_validation_error'>No hemos podido validar su CURP, por favor inténtelo de nuevo.</p>"
        },
        {
          type: "html",
          name: "Error wrong format CURP",
          html: `<p hidden class='sv_q_erbox' id='curp_format_error'>La CURP consiste de 18 caracteres
            en el siguiente orden: 4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o 7 letras 
            y 1 dígito</p>`
        },
        {
          type: "panel",
          innerIndent: 1,
          name: "curp_panel",
          elements: [
            {
              title: {
                en: "Names",
                "es-MX": "Nombres*",
              },
              name: "guarantor_first_name",
              type: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Last name",
                "es-MX": "Apellido paterno*",
              },
              name: "guarantor_last_name",
              type: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Mother's last name",
                "es-MX": "Apellido materno*",
              },
              name: "guarantor_mother_name",
              type: "text",
            },
            {
              title: { en: "Date of birth", "es-MX": "Fecha de nacimiento*" },
              type: "text",
              name: "date_of_birth",
              isRequired: required,
              inputType: "date",
              validators: [
                {
                  type: "expression",
                  text: {
                    en: "You must be older than 21 and younger than 75 to apply",
                    "es-MX":
                      "Debes tener más de 21 y menos de 75 años menos para postularte.",
                  },
                  expression:
                    "(age({date_of_birth}) > 21) && (age({date_of_birth}) < 75)",
                },
              ],
            },
            {
              title: {
                en: "Federal entity of birth",
                "es-MX": "Entidad federativa de nacimiento*",
              },
              type: "text",
              name: "custom_text_57",
              isRequired: required,
            },
            {
              title: { en: "Sex", "es-MX": "Sexo" },
              type: "text",
              name: "gender",
              isRequired: required,
            },
          ]
        },
      ],
    },
    // Page 3
    {
      title: {
        en: "Guarantor Information",
        "es-MX": "Información del aval",
      },
      name: "personal",
      elements: [
        {
          title: {
            en: "RFC",
            "es-MX": "RFC*",
          },
          name: "custom_text_55",
          type: "text",
          isRequired: required,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct Tax Id Number (RFC) of 4 letters and 6 digits",
                "es-MX":
                  "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
              },
              regex: `^[A-Za-z]{4}[0-9]{6}$`,
            },
          ],
          placeHolder: "VECJ880326",
        },
        {
          title: {
            en: "Homoclave",
            "es-MX": "Homoclave",
          },
          name: "custom_text_56",
          type: "text",
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct homoclave of 3 characters",
                "es-MX":
                  "Por favor ingrese una homoclave correcta de 3 caracteres",
              },
              regex: `^[0-9A-Za-z]{3}$`,
            },
          ],
        },
        {
          title: {
            en: "front image (INE)",
            "es-MX": "Imagen parte de adelante (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file",
          isRequired: required,
        },
        {
          title: {
            en: "back image (INE)",
            "es-MX": "Imagen parte de atrás (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file_back",
          isRequired: required,
        },
        {
          title: {
            en: "To verify your identification, please upload a picture of yoursel holding you ID",
            "es-MX":
              "Para verificar tu identidad por favor, sube una foto sujetando dicha tarjeta de identificación (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file_with_avatar",
          isRequired: required,
        },
        {
          title: { en: "State", "es-MX": "Estado" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_48",
          renderAs: "select2",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/entidadfederativa/entidades-federativa`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "idEntidadFederativa_custom_text_48", visible: false},
        {
          title: { en: "Municipality", "es-MX": "Municipio" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_47",
          renderAs: "select2",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/municipio/id-municipio-by-entidadfederativa?idEntidadFederativa={idEntidadFederativa_custom_text_48}`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "idMunicipio_custom_text_47", visible: false},
        {
          type: "dropdown",
          name: "custom_text_49",
          title: { en: "Suburb", "es-MX": "Colonia" },
          renderAs: "select2",
          isRequired: required,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/colonia/colonias-municipio?idMunicipio={idMunicipio_custom_text_47}&idEntidadFederativa={idEntidadFederativa_custom_text_48}`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "codigoPostal_custom_text_46", visible: false},
        {
          title: {
            en: "Postal Code",
            "es-MX": "Código postal"
          },
          type: "text",
          isRequired: required,
          name: "custom_text_46",
          readOnly: true,
        },
        {
          title: { en: "Address", "es-MX": "Domicilio" },
          type: "text",
          name: "custom_text_45",
          isRequired: required,
        },
        {
          title: {
            en: "Occupation",
            "es-MX": "Ocupación*"
          },
          type: "text",
          name: "custom_text_13",
          isRequired: required
        },
        {
          title: {
            en: "Seniority in the Occupation",
            "es-MX": "Antigüedad en la Ocupación*"
          },
          type: "text",
          inputType: "number",
          name: "custom_float_9",
          isRequired:required,
          validators: [expressions.amount_greater_than_zero("custom_float_9")]
        },
        {
          title: {
            en: "Phone number",
            "es-MX": "Teléfono*",
          },
          name: "guarantor_phone",
          type: "phone",
          isRequired: required,
        },
        {
          html: {
            en: `
                  AUTHORIZATION TO REQUEST CREDIT REPORTS INDIVIDUALS / MORAL PERSONS
                  
                  Through this channel, I expressly authorize SOLUCION ASEA SA DE CV SFP, hereinafter CREDICAPITAL, to carry out investigations, through its authorized officials, about my credit behavior or that of the Company that I represent in the credit information companies that they deem appropriate. .
                  
                  Likewise, I declare that I know the nature and scope of the information that will be requested, the use that CREDICAPITAL will make of such information and that it may make periodic inquiries about my history or that of the company I represent, consisting that this authorization is It is in force for a period of 3 years counted from its issuance and in any case during the time that the legal relationship is maintained.
                  
                  In the event that the applicant is a legal person, I declare under protest of telling the truth that I am the legal representative of the company mentioned in this authorization; stating that as of the signing date of this authorization the powers of attorney have not been revoked, limited or modified in any way.
                  
                  I agree and accept that this document remains the property of CREDICAPITAL and / or the Credit Information Society consulted for the purposes of control and compliance with article 28 of the Law to Regulate Credit Information Companies, which states that companies may only Provide information to a user, when he has the express authorization of the client by means of his autograph signature.
                `,
            "es-MX": `
                  <strong>AUTORIZACIÓN PARA SOLICITAR REPORTES DE CRÉDITO PERSONAS FÍSICAS / PERSONAS MORALES</strong><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Por este conducto autorizo expresamente a SOLUCION ASEA S.A DE C.V S.F.P., en adelante CREDICAPITAL, para que por conducto de sus funcionarios facultados lleve a  cabo investigaciones, sobre mi  comportamiento crediticio o  el  de  la Empresa que represento en las sociedades de información crediticia que estimen conveniente.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitara, del uso que CREDICAPITAL, hará de tal información y de que esta podrá realizar consultas periódicas sobre mi historial o el de la empresa que represento, consistiendo que esta autorización se encuentre vigente por un periodo de 3 años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relación jurídica.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">El caso de que el solicitante sea una persona moral, declaro bajo protesta de decir la verdad ser representante legal de la empresa mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados ni modificados en forma alguna.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Estoy de acuerdo y acepto que este documento quede bajo propiedad de CREDICAPITAL y/o Sociedad de Información Crediticia consultada para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a las sociedades de información Crediticia, mismo que señala que las sociedades solo podrán proporcionar información a un usuario, cuando este cuente con la autorización expresa del cliente mediante su firma autógrafa.</p><br><br>
                `,
          },
          type: "html",
        },
        {
          title: " ",
          type: "checkbox",
          name: "custom_jsonfield_8",
          isRequired: required,
          choices: [
            {
              value: "Acepto",
              text: {
                en: "I agree",
                "es-MX": "Acepto",
              },
            },
          ],
          validators: [
            {
              type: "expression",
              text: {
                en: "You should accept before continuing",
                "es-MX": "Debes aceptar antes de continuar",
              },
              expression: "{custom_jsonfield_8} contains 'acepto'",
            },
          ],
        },
        {
          title: {
            en: "Date the guarantor intake is signed:",
            "es-MX": "Fecha de firma del aval:",
          },
          type: "expression",
          name: "custom_text_14",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          type: "signaturepad",
          name: "signature",
          isRequired: required,
          title: {
            en: "Guarantors signature ",
            "es-MX": "Firma del aval",
          },
        },
      ]
    },
    // Page 4
    {
      title: {
        en: "Guarantor patrimonial information",
        "es-MX": "Información patrimonial del aval",
      },
      name: "informacion_patrimonial_aval",
      elements: [
        {
          type: "panel",
          title: {
            en: "Equity balance",
            "es-MX": "Balance patrimonial",
          },
          name: "custom_jsonfield_6",
          elements: [
            {
              type: "matrixdynamic",
              title: {
                en: "Assets",
                "es-MX": "Activos",
              },
              name: "custom_jsonfield_6",
              minRowCount: 1,
              maxRowCount: 2,
              rowCount: 1,
              columns: [
                {
                  title: {
                    en: "Accounts",
                    "es-MX": "Cuentas*",
                  },
                  cellType: "dropdown",
                  name: "cuentas",
                  isRequired: required,
                  choices: [
                    "Efectivo/Bancos",
                    "Inversión en Acciones",
                    "Bienes Inmuebles",
                    "Bienes Muebles",
                    "Otros Activos",
                  ],
                },
                {
                  title: {
                    en: "Description",
                    "es-MX": "Descripción"
                  },
                  cellType: "text",
                  name: "descripcion",
                  isRequired: required
                },
                {
                  title: {
                    en: "Amount",
                    "es-MX": "Monto*",
                  },
                  cellType: "text",
                  inputType: "number",
                  name: "monto",
                  isRequired: required,
                },
              ],
            },
            {
              title: {
                en: "Assets total",
                "es-MX": "Activos total",
              },
              type: "expression",
              name: "custom_float_25",
              expression: "fixed(sumInArray({custom_jsonfield_6}, 'monto'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "matrixdynamic",
              title: {
                en: "Passives",
                "es-MX": "Pasivos",
              },
              name: "custom_jsonfield_7",
              minRowCount: 1,
              maxRowCount: 2,
              rowCount: 1,
              columns: [
                {
                  title: {
                    en: "Accounts",
                    "es-MX": "Cuentas*",
                  },
                  cellType: "dropdown",
                  name: "cuentas",
                  isRequired: required,
                  choices: [
                    "Deudas según SIC",
                    "Otras deudas no declaradas según el SIC",
                  ],
                },
                {
                  title: {
                    en: "Description",
                    "es-MX": "Descripción"
                  },
                  cellType: "text",
                  name: "descripcion",
                  isRequired: required
                },
                {
                  title: {
                    en: "Amount",
                    "es-MX": "Monto*",
                  },
                  cellType: "text",
                  inputType: "number",
                  name: "monto",
                  isRequired: required,
                },
              ],
            },
            {
              title: {
                en: "Passives total",
                "es-MX": "Pasivos total",
              },
              type: "expression",
              name: "custom_float_26",
              expression: "fixed(sumInArray({custom_jsonfield_7}, 'monto'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              title: {
                en: "Total equity",
                "es-MX": "Patrimonio total",
              },
              type: "expression",
              name: "custom_float_27",
              expression:
                "fixed(subtract({custom_float_25},{custom_float_26}), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          type: "panel",
          title: {
            en: "Declared income and expenses",
            "es-MX": "Ingresos y gastos declarados",
          },
          name: "ingresos_y_gastos_declarados_panel",
          elements: [
            {
              type: "panel",
              title: {
                en: "Monthly income",
                "es-MX": "Ingresos mensuales",
              },
              name: "ingresos_mensuales_panel",
              elements: [
                {
                  title: {
                    en: "Salary for employment",
                    "es-MX": "Salario por empleo*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_1",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Deal",
                    "es-MX": "Negocio*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_2",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Family contributions",
                    "es-MX": "Aportaciones familiares*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_3",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Total",
                    "es-MX": "Total Ingresos mensuales",
                  },
                  type: "expression",
                  name: "custom_float_4",
                  isRequired: required,
                  expression:
                    "fixed(add({custom_float_1},{custom_float_2},{custom_float_3}))", // Floating Point Proof
                  displayStyle: "currency",
                  currency: "MXN",
                },
              ],
            },
            {
              type: "panel",
              title: {
                en: "Monthly expenses",
                "es-MX": "Egresos mensuales",
              },
              name: "egresos_mensuales_panel",
              elements: [
                {
                  title: {
                    en: "Family",
                    "es-MX": "Familiares*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_5",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Business (purchase of merchandise, operating expenses)",
                    "es-MX":
                      "Negocio (compra de mercancía, gastos de operación)*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_6",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Total",
                    "es-MX": "Total Egresos mensuales",
                  },
                  type: "expression",
                  name: "custom_float_7",
                  isRequired: required,
                  expression: "fixed(add({custom_float_5},{custom_float_6}))",
                  displayStyle: "currency",
                  currency: "MXN",
                },
              ],
            },
            {
              title: {
                en: "Net monthly income",
                "es-MX": "Ingresos netos mensuales",
              },
              type: "expression",
              name: "custom_float_8",
              isRequired: required,
              expression: "fixed(subtract({custom_float_4},{custom_float_7}))", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          html: {
            en: "I declare under protest of telling the truth, that the data recorded here and the documentation provided are reliable. I am aware and accept that this document remains under the protection of CREDICAPITAL for the purposes of control and compliance with our policies.",
            "es-MX":
              "Declaro bajo protesta de decir verdad, que los datos aquí asentados y la documentación entregada son fidedignos. Estoy consciente y acepto que este documento queda bajo resguardo de CREDICAPITAL para efectos de control y cumplimiento nuestras políticas.",
          },
          type: "html",
        },
        {
          type: "expression",
          title: {
            en: "Guarantor name",
            "es-MX": "Nombre del aval",
          },
          expression: `getFullName({guarantor_first_name},{custom_text_2},{custom_text_3})`,
        },
        {
          title: { en: "sign", "es-MX": "Firma" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
});

export const guarantor_credicapital_recurring_client = (clientUrlForCatalog) => ({
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Page 1
    {
      title: { en: "Guarantor acceptance", "es-MX": "Aceptación de aval" },
      name: "aceptacion_de_aval",
      elements: [
        {
          title: {
            en: "Welcome {guarantor_first_name}, you are invited to be the guarantor/cooperative of {user_first_name} for a loan amount of {amount} RWF. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.",
            "es-MX":
              "Bienvenido {guarantor_first_name}, estás propuesto para ser el aval de {user_first_name} por un monto de préstamo de $ {amount}. Para cumplir con este requerimiento, se te pedirá información sobre ti. Selecciona aceptas o no. Si tiene alguna pregunta, por favor contáctenos.",
          },
          name: "guarantor_refused",
          type: "radiogroup",
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: "I accept",
                "es-MX": "Acepto",
              },
            },
            {
              value: true,
              text: {
                en: "I don't accept",
                "es-MX": "No acepto",
              },
            },
          ],
        },
      ],
    },
    // Page 2
    {
      title: {
        en: "CURP Authorization",
        "es-MX": "Autorización de CURP",
      },
      name: "curp_authorization",
      elements: [
        {
          title: {
            en: "curp",
            "es-MX": "CURP*",
          },
          name: "custom_text_108",
          type: "text",
          inputType: "text",
          isRequired: required,
          validators: [expressions.curp()],
          placeHolder: "HEGG560427MVZRRL04",
        },
        {
          type: "html",
          name: "Consultar CURP",
          html: "<button hidden class='curp-button'>Consultar CURP</button>",
        },
        {
          type: "html",
          name: "Error consulta CURP",
          html: "<p hidden class='sv_q_erbox' id='curp_validation_error'>No hemos podido validar su CURP, por favor inténtelo de nuevo.</p>"
        },
        {
          type: "html",
          name: "Error wrong format CURP",
          html: `<p hidden class='sv_q_erbox' id='curp_format_error'>La CURP consiste de 18 caracteres
            en el siguiente orden: 4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o 7 letras 
            y 1 dígito</p>`
        },
        {
          type: "panel",
          innerIndent: 1,
          name: "curp_panel",
          elements: [
            {
              title: {
                en: "Names",
                "es-MX": "Nombres*",
              },
              name: "guarantor_first_name",
              type: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Last name",
                "es-MX": "Apellido paterno*",
              },
              name: "guarantor_last_name",
              type: "text",
              isRequired: required,
            },
            {
              title: {
                en: "Mother's last name",
                "es-MX": "Apellido materno*",
              },
              name: "guarantor_mother_name",
              type: "text",
            },
            {
              title: { en: "Date of birth", "es-MX": "Fecha de nacimiento*" },
              type: "text",
              name: "date_of_birth",
              isRequired: required,
              inputType: "date",
              validators: [
                {
                  type: "expression",
                  text: {
                    en: "You must be older than 21 and younger than 75 to apply",
                    "es-MX":
                      "Debes tener más de 21 y menos de 75 años menos para postularte.",
                  },
                  expression:
                    "(age({date_of_birth}) > 21) && (age({date_of_birth}) < 75)",
                },
              ],
            },
            {
              title: {
                en: "Federal entity of birth",
                "es-MX": "Entidad federativa de nacimiento*",
              },
              type: "text",
              name: "custom_text_57",
              isRequired: required,
            },
            {
              title: { en: "Sex", "es-MX": "Sexo" },
              type: "text",
              name: "gender",
              isRequired: required,
            },
          ]
        },
      ],
    },
    // Page 3
    {
      title: {
        en: "Guarantor Information",
        "es-MX": "Información del aval",
      },
      name: "personal",
      elements: [
        {
          title: {
            en: "RFC",
            "es-MX": "RFC*",
          },
          name: "custom_text_55",
          type: "text",
          isRequired: required,
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct Tax Id Number (RFC) of 4 letters and 6 digits",
                "es-MX":
                  "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
              },
              regex: `^[A-Za-z]{4}[0-9]{6}$`,
            },
          ],
          placeHolder: "VECJ880326",
        },
        {
          title: {
            en: "Homoclave",
            "es-MX": "Homoclave",
          },
          name: "custom_text_56",
          type: "text",
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "Please enter a correct homoclave of 3 characters",
                "es-MX":
                  "Por favor ingrese una homoclave correcta de 3 caracteres",
              },
              regex: `^[0-9A-Za-z]{3}$`,
            },
          ],
        },
        {
          title: {
            en: "front image (INE)",
            "es-MX": "Imagen parte de adelante (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file",
          isRequired: required,
        },
        {
          title: {
            en: "back image (INE)",
            "es-MX": "Imagen parte de atrás (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file_back",
          isRequired: required,
        },
        {
          title: {
            en: "To verify your identification, please upload a picture of yoursel holding you ID",
            "es-MX":
              "Para verificar tu identidad por favor, sube una foto sujetando dicha tarjeta de identificación (INE)",
          },
          type: "file_uploader",
          accept: "image/*,application/pdf",
          name: "id_file_with_avatar",
          isRequired: required,
        },
        {
          title: { en: "State", "es-MX": "Estado" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_48",
          renderAs: "select2",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/entidadfederativa/entidades-federativa`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "idEntidadFederativa_custom_text_48", visible: false},
        {
          title: { en: "Municipality", "es-MX": "Municipio" },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_47",
          renderAs: "select2",
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/municipio/id-municipio-by-entidadfederativa?idEntidadFederativa={idEntidadFederativa_custom_text_48}`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "idMunicipio_custom_text_47", visible: false},
        {
          type: "dropdown",
          name: "custom_text_49",
          title: { en: "Suburb", "es-MX": "Colonia" },
          renderAs: "select2",
          isRequired: required,
          choicesByUrl: {
            url: `${clientUrlForCatalog}/api/colonia/colonias-municipio?idMunicipio={idMunicipio_custom_text_47}&idEntidadFederativa={idEntidadFederativa_custom_text_48}`,
            titleName: "Descripcion",
            valueName: "Descripcion",
            attachOriginalItems: true,
          },
        },
        {type: "text", name: "codigoPostal_custom_text_46", visible: false},
        {
          title: {
            en: "Postal Code",
            "es-MX": "Código postal"
          },
          type: "text",
          isRequired: required,
          name: "custom_text_46",
          readOnly: true,
        },
        {
          title: { en: "Address", "es-MX": "Domicilio" },
          type: "text",
          name: "custom_text_45",
          isRequired: required,
        },
        {
          title: {
            en: "Occupation",
            "es-MX": "Ocupación*"
          },
          type: "text",
          name: "custom_text_13",
          isRequired: required
        },
        {
          title: {
            en: "Seniority in the Occupation",
            "es-MX": "Antigüedad en la Ocupación*"
          },
          type: "text",
          inputType: "number",
          name: "custom_float_9",
          isRequired:required,
          validators: [expressions.amount_greater_than_zero("custom_float_9")]
        },
        {
          title: {
            en: "Phone number",
            "es-MX": "Teléfono*",
          },
          name: "guarantor_phone",
          type: "phone",
          isRequired: required,
        },
        {
          html: {
            en: `
                  AUTHORIZATION TO REQUEST CREDIT REPORTS INDIVIDUALS / MORAL PERSONS
                  
                  Through this channel, I expressly authorize SOLUCION ASEA SA DE CV SFP, hereinafter CREDICAPITAL, to carry out investigations, through its authorized officials, about my credit behavior or that of the Company that I represent in the credit information companies that they deem appropriate. .
                  
                  Likewise, I declare that I know the nature and scope of the information that will be requested, the use that CREDICAPITAL will make of such information and that it may make periodic inquiries about my history or that of the company I represent, consisting that this authorization is It is in force for a period of 3 years counted from its issuance and in any case during the time that the legal relationship is maintained.
                  
                  In the event that the applicant is a legal person, I declare under protest of telling the truth that I am the legal representative of the company mentioned in this authorization; stating that as of the signing date of this authorization the powers of attorney have not been revoked, limited or modified in any way.
                  
                  I agree and accept that this document remains the property of CREDICAPITAL and / or the Credit Information Society consulted for the purposes of control and compliance with article 28 of the Law to Regulate Credit Information Companies, which states that companies may only Provide information to a user, when he has the express authorization of the client by means of his autograph signature.
                `,
            "es-MX": `
                  <strong>AUTORIZACIÓN PARA SOLICITAR REPORTES DE CRÉDITO PERSONAS FÍSICAS / PERSONAS MORALES</strong><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Por este conducto autorizo expresamente a SOLUCION ASEA S.A DE C.V S.F.P., en adelante CREDICAPITAL, para que por conducto de sus funcionarios facultados lleve a  cabo investigaciones, sobre mi  comportamiento crediticio o  el  de  la Empresa que represento en las sociedades de información crediticia que estimen conveniente.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitara, del uso que CREDICAPITAL, hará de tal información y de que esta podrá realizar consultas periódicas sobre mi historial o el de la empresa que represento, consistiendo que esta autorización se encuentre vigente por un periodo de 3 años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relación jurídica.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">El caso de que el solicitante sea una persona moral, declaro bajo protesta de decir la verdad ser representante legal de la empresa mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados ni modificados en forma alguna.</p><br><br>
    
                  <p style="text-align:justify;word-break:keep-all;">Estoy de acuerdo y acepto que este documento quede bajo propiedad de CREDICAPITAL y/o Sociedad de Información Crediticia consultada para efectos de control y cumplimiento del artículo 28 de la Ley para Regular a las sociedades de información Crediticia, mismo que señala que las sociedades solo podrán proporcionar información a un usuario, cuando este cuente con la autorización expresa del cliente mediante su firma autógrafa.</p><br><br>
                `,
          },
          type: "html",
        },
        {
          title: " ",
          type: "checkbox",
          name: "custom_jsonfield_8",
          isRequired: required,
          choices: [
            {
              value: "Acepto",
              text: {
                en: "I agree",
                "es-MX": "Acepto",
              },
            },
          ],
          validators: [
            {
              type: "expression",
              text: {
                en: "You should accept before continuing",
                "es-MX": "Debes aceptar antes de continuar",
              },
              expression: "{custom_jsonfield_8} contains 'acepto'",
            },
          ],
        },
        {
          title: {
            en: "Date the guarantor intake is signed:",
            "es-MX": "Fecha de firma del aval:",
          },
          type: "expression",
          name: "custom_text_14",
          expression: `daysFromNow(0, 0, 'es-MX')`,
        },
        {
          type: "signaturepad",
          name: "signature",
          isRequired: required,
          title: {
            en: "Guarantors signature ",
            "es-MX": "Firma del aval",
          },
        },
      ]
    },
    // Page 4
    {
      title: {
        en: "Guarantor patrimonial information",
        "es-MX": "Información patrimonial del aval",
      },
      name: "informacion_patrimonial_aval",
      elements: [
        {
          type: "panel",
          title: {
            en: "Equity balance",
            "es-MX": "Balance patrimonial",
          },
          name: "custom_jsonfield_6",
          elements: [
            {
              type: "matrixdynamic",
              title: {
                en: "Assets",
                "es-MX": "Activos",
              },
              name: "custom_jsonfield_6",
              minRowCount: 1,
              maxRowCount: 2,
              rowCount: 1,
              columns: [
                {
                  title: {
                    en: "Accounts",
                    "es-MX": "Cuentas*",
                  },
                  cellType: "dropdown",
                  name: "cuentas",
                  isRequired: required,
                  choices: [
                    "Efectivo/Bancos",
                    "Inversión en Acciones",
                    "Bienes Inmuebles",
                    "Bienes Muebles",
                    "Otros Activos",
                  ],
                },
                {
                  title: {
                    en: "Amount",
                    "es-MX": "Monto*",
                  },
                  cellType: "text",
                  inputType: "number",
                  name: "monto",
                  isRequired: required,
                },
              ],
            },
            {
              title: {
                en: "Assets total",
                "es-MX": "Activos total",
              },
              type: "expression",
              name: "custom_float_25",
              expression: "fixed(sumInArray({custom_jsonfield_6}, 'monto'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              type: "matrixdynamic",
              title: {
                en: "Passives",
                "es-MX": "Pasivos",
              },
              name: "custom_jsonfield_7",
              minRowCount: 1,
              maxRowCount: 2,
              rowCount: 1,
              columns: [
                {
                  title: {
                    en: "Accounts",
                    "es-MX": "Cuentas*",
                  },
                  cellType: "dropdown",
                  name: "cuentas",
                  isRequired: required,
                  choices: [
                    "Deudas según SIC",
                    "Otras deudas no declaradas según el SIC",
                  ],
                },
                {
                  title: {
                    en: "Description",
                    "es-MX": "Descripción"
                  },
                  cellType: "text",
                  name: "descripcion",
                  isRequired: required
                },
                {
                  title: {
                    en: "Amount",
                    "es-MX": "Monto*",
                  },
                  cellType: "text",
                  inputType: "number",
                  name: "monto",
                  isRequired: required,
                },
              ],
            },
            {
              title: {
                en: "Passives total",
                "es-MX": "Pasivos total",
              },
              type: "expression",
              name: "custom_float_26",
              expression: "fixed(sumInArray({custom_jsonfield_7}, 'monto'), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
            {
              title: {
                en: "Total equity",
                "es-MX": "Patrimonio total",
              },
              type: "expression",
              name: "custom_float_27",
              expression:
                "fixed(subtract({custom_float_25},{custom_float_26}), 2)", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          type: "panel",
          title: {
            en: "Declared income and expenses",
            "es-MX": "Ingresos y gastos declarados",
          },
          name: "ingresos_y_gastos_declarados_panel",
          elements: [
            {
              type: "panel",
              title: {
                en: "Monthly income",
                "es-MX": "Ingresos mensuales",
              },
              name: "ingresos_mensuales_panel",
              elements: [
                {
                  title: {
                    en: "Salary for employment",
                    "es-MX": "Salario por empleo*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_1",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Deal",
                    "es-MX": "Negocio*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_2",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Family contributions",
                    "es-MX": "Aportaciones familiares*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_3",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Total",
                    "es-MX": "Total Ingresos mensuales",
                  },
                  type: "expression",
                  name: "custom_float_4",
                  isRequired: required,
                  expression:
                    "fixed(add({custom_float_1},{custom_float_2},{custom_float_3}))", // Floating Point Proof
                  displayStyle: "currency",
                  currency: "MXN",
                },
              ],
            },
            {
              type: "panel",
              title: {
                en: "Monthly expenses",
                "es-MX": "Egresos mensuales",
              },
              name: "egresos_mensuales_panel",
              elements: [
                {
                  title: {
                    en: "Family",
                    "es-MX": "Familiares*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_5",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Business (purchase of merchandise, operating expenses)",
                    "es-MX":
                      "Negocio (compra de mercancía, gastos de operación)*",
                  },
                  type: "text",
                  inputType: "number",
                  name: "custom_float_6",
                  isRequired: required,
                },
                {
                  title: {
                    en: "Total",
                    "es-MX": "Total Egresos mensuales",
                  },
                  type: "expression",
                  name: "custom_float_7",
                  isRequired: required,
                  expression: "fixed(add({custom_float_5},{custom_float_6}))",
                  displayStyle: "currency",
                  currency: "MXN",
                },
              ],
            },
            {
              title: {
                en: "Net monthly income",
                "es-MX": "Ingresos netos mensuales",
              },
              type: "expression",
              name: "custom_float_8",
              isRequired: required,
              expression: "fixed(subtract({custom_float_4},{custom_float_7}))", // Floating Point Proof
              displayStyle: "currency",
              currency: "MXN",
            },
          ],
        },
        {
          html: {
            en: "I declare under protest of telling the truth, that the data recorded here and the documentation provided are reliable. I am aware and accept that this document remains under the protection of CREDICAPITAL for the purposes of control and compliance with our policies.",
            "es-MX":
              "Declaro bajo protesta de decir verdad, que los datos aquí asentados y la documentación entregada son fidedignos. Estoy consciente y acepto que este documento queda bajo resguardo de CREDICAPITAL para efectos de control y cumplimiento nuestras políticas.",
          },
          type: "html",
        },
        {
          type: "expression",
          title: {
            en: "Guarantor name",
            "es-MX": "Nombre del aval",
          },
          expression: `getFullName({guarantor_first_name},{custom_text_2},{custom_text_3})`,
        },
        {
          title: { en: "sign", "es-MX": "Firma" },
          type: "signaturepad",
          isRequired: true,
          name: "signature",
        },
      ],
    },
  ],
});